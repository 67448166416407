/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import React from 'react'
import './Content.css'

const CATEGORIES = [
    'Javascript',
    'Web',
    'Rust',
    'Finance'
];
export default () => {
    return (
        <>
            <div class="content__container">
                <p class="content__container__text">
                    I write about
                </p>
                <Box sx={{
                    mt: ['-10px', '-8px', '-8px', '0'],
                    ml: ['9rem', '12rem', '12rem', '17rem'],
                }}>
                    <ul class="content__container__list">
                        {CATEGORIES.map(c => <li key={c} class="content__container__list__item">{c}</li>)}
                    </ul>
                </Box>
            </div>
        </>
    );
}